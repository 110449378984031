<template>
  <v-sheet dark class="transparent">
    <responsive-layout>
      <v-container style="min-height: 100vh" class="py-8">
        <v-row justify="start" align="center">
          <v-col cols="12" lg="6">
            <h2 class="text-left">
              Welcome to Pandora SMC Event Admin, {{ me.name }}
            </h2>
          </v-col>
        </v-row>
        <v-row justify="start" align="center" v-if="event && currentSession">
          <v-col cols="12" class="d-flex flex-column" style="gap: 1rem">
            <v-card title="Manage main event">
              <v-card-title>Manage main event</v-card-title>
              <v-card-text class="text-left">
                <v-form>
                  <v-container fluid>
                    <!-- Manage day -->
                    <v-row class="py-4">
                      <v-col>
                        <h4 class="mb-2">Change day</h4>

                        <v-chip-group
                          v-model="form.day"
                          mandatory
                          active-class="primary black--text"
                        >
                          <v-chip
                            v-for="(day, idx) in formOptions.days"
                            :key="idx"
                            :value="day.value"
                          >
                            {{ day.name }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <!-- Manage state -->
                    <v-row class="py-4">
                      <v-col>
                        <h4 class="mb-2">Change state</h4>

                        <v-chip-group
                          v-model="form.state"
                          mandatory
                          active-class="primary black--text"
                        >
                          <v-chip
                            v-for="(state, idx) in formOptions.states"
                            :key="idx"
                            :value="state.value"
                          >
                            {{ state.name }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <!-- Manage current session -->
                    <v-row class="py-4">
                      <v-col cols="12" lg="6">
                        <h4 class="mb-2">Change current session</h4>

                        <v-chip-group
                          v-model="form.current_session"
                          mandatory
                          active-class="primary black--text"
                          column
                        >
                          <v-chip
                            v-for="session in sessionsForCurrentDay"
                            :key="session.id"
                            :value="session.id"
                          >
                            {{ session.name }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-8">
                <v-btn
                  :loading="isLoading"
                  :disabled="isLoading"
                  @click.stop="onSubmit"
                  color="primary"
                  class="black--text"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="start" align="center" v-if="ooEvent">
          <v-col cols="12" class="d-flex flex-column" style="gap: 1rem">
            <v-card title="Manage main event">
              <v-card-title>Manage OO event</v-card-title>
              <v-card-text class="text-left">
                <v-form>
                  <v-container fluid>
                    <!-- Manage day -->
                    <v-row class="py-4">
                      <v-col>
                        <h4 class="mb-2">Change day</h4>

                        <v-chip-group
                          v-model="ooForm.day"
                          mandatory
                          active-class="primary black--text"
                        >
                          <v-chip
                            v-for="(day, idx) in formOptions.days"
                            :key="idx"
                            :value="day.value"
                          >
                            {{ day.name }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <!-- Manage state -->
                    <v-row class="py-4">
                      <v-col>
                        <h4 class="mb-2">Change state</h4>

                        <v-chip-group
                          v-model="ooForm.state"
                          mandatory
                          active-class="primary black--text"
                        >
                          <v-chip
                            v-for="(state, idx) in formOptions.states"
                            :key="idx"
                            :value="state.value"
                          >
                            {{ state.name }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <!-- Manage current session -->
                    <v-row class="py-4">
                      <v-col cols="12" lg="6">
                        <h4 class="mb-2">Change current session</h4>

                        <v-chip-group
                          v-model="ooForm.current_session"
                          mandatory
                          active-class="primary black--text"
                          column
                        >
                          <v-chip
                            v-for="session in sessionsForCurrentOODay"
                            :key="session.id"
                            :value="session.id"
                          >
                            {{ session.name }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-8">
                <v-btn
                  :loading="isOOLoading"
                  :disabled="isOOLoading"
                  @click.stop="onOOSubmit"
                  color="primary"
                  class="black--text"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </responsive-layout>
  </v-sheet>
</template>

<script>
import axios from "axios";
import ResponsiveLayout from "@/layouts/ResponsiveLayout.vue";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    ResponsiveLayout,
  },

  data() {
    return {
      isLoading: false,
      isOOLoading: false,
      form: {},
      ooForm: {},
      formOptions: {
        states: [
          {
            name: "Waiting",
            value: "WAITING",
          },
          {
            name: "Live",
            value: "LIVE",
          },
          {
            name: "Break",
            value: "BREAK",
          },
          {
            name: "On Demand",
            value: "ONDEMAND",
          },
        ],
        days: [
          {
            name: "Day 1",
            value: 1,
          },
          {
            name: "Day 2",
            value: 2,
          },
          {
            name: "Day 3",
            value: 3,
          },
        ],
      },
    };
  },

  async mounted() {
    await this.$store.dispatch("updateSessions");
    await this.$store.dispatch("updateEvent");

    this.form = {
      day: this.event.day,
      state: this.event.state,
      current_session: this.currentSession.id,
    };

    this.ooForm = {
      day: this.ooEvent.day,
      state: this.ooEvent.state,
      current_session: this.ooEvent.current_session,
    };
  },

  computed: {
    ...mapState(["event", "ooEvent", "sessions"]),
    ...mapGetters(["me", "currentSession", "currentOOSession"]),
    sessionsForCurrentDay() {
      return this.sessions.filter(
        (session) => session.day === this.form.day && !session.oo_session
      );
    },
    sessionsForCurrentOODay() {
      return this.sessions.filter(
        (session) => session.day === this.ooForm.day && session.oo_session
      );
    },
  },

  methods: {
    async onSubmit() {
      this.isLoading = true;
      const url = `${process.env.VUE_APP_API_URL}event/change_state`;

      const data = {
        ...this.form,
        event_id: 1,
      };

      await axios.post(url, data);
      this.isLoading = false;
    },

    async onOOSubmit() {
      this.isOOLoading = true;
      const url = `${process.env.VUE_APP_API_URL}event/change_state`;

      const data = {
        ...this.ooForm,
        event_id: 2,
      };

      await axios.post(url, data);
      this.isOOLoading = false;
    },
  },
};
</script>

<style></style>
